.newsletterFormContainer {
  @apply flex flex-col gap-1;
  min-width: 33%;
}

.newsletterForm {
  @apply mt-4 flex flex-col gap-2 md:gap-0 md:flex-row md:w-full items-stretch overflow-hidden;
}

.newsletterFormContainer.header .newsletterForm {
  @apply mt-0 overflow-visible flex-row gap-0;
}

.newsletterEmailInput {
  @apply flex-grow font-normal text-2xs lg:text-2xs text-white border rounded-xs md:rounded-none md:rounded-l-xs md:border-r-0 md:border-l md:border-t md:border-b border-white p-2 bg-footer-bg;
  min-width: 240px;
}

.newsletterFormContainer.header .newsletterEmailInput {
  @apply text-dark-text-color border-none bg-blog-subscribe-field-shade text-3xs py-2.5 pl-4;
}

.newsletterFormContainer.header .newsletterEmailInput::placeholder {
  @apply text-dark-text-color;
}

.newsletterSubmit {
  @apply py-2 md:px-3.5 md:py-1 font-semibold text-3xs lg:text-2xs text-black-gray bg-white block whitespace-nowrap rounded-xs md:rounded-none md:rounded-r-xs;
}

.newsletterFormContainer.header .newsletterSubmit {
  @apply text-white border-none bg-background-color rounded-none rounded-r-xs px-3.5;
}

.newsletterMessage {
  @apply text-5xs text-light-gray-color text-right;
}

.newsletterFormContainer.header .newsletterMessage {
  @apply text-dark-text-color;
}

.newsletterError {
  @apply text-5xs text-red text-right;
}
