#siteFooter {
  @apply bg-footer-bg text-white text-4xs md:text-3xs font-normal tracking-normal pt-20 pb-16 z-50;
}

.footerContainer {
  @apply container grid grid-cols-1 md:grid-cols-4 mx-auto px-6 md:px-6 gap-24 md:gap-4;
}

.footerContainer h6 {
  @apply font-semibold;
}

.footerContainer a {
  @apply no-underline;
}

.footerContainer li {
  @apply mt-3.5;
}

.footerLeftColumn p {
  @apply mt-14 md:w-3/4;
}

.footerLeftColumn p a {
  @apply font-semibold underline;
}

.footerLeftColumn .btnWhite {
  @apply mt-6 px-4 py-2 text-darkest-gray-color bg-white rounded-xs font-semibold text-2xs tracking-tight inline-block;
}

.socialMedia h6 {
  @apply text-left md:text-right;
}

.socialMediaButtons {
  @apply flex flex-row gap-4 justify-start md:justify-end;
}

.rightColumn {
  @apply md:flex flex-col items-start justify-start md:justify-start mb-6;
}

.newsletterSection {
  @apply container mx-5 md:mx-auto mt-24 flex flex-col md:flex-row py-8 px-1 md:px-2 border-t border-dark-gray-color border-b items-start md:items-center justify-start md:justify-stretch w-auto;
}

.newsletterTitleContainer {
  @apply flex flex-col md:grow;
}

.newsletterTitleContainer h6 {
  @apply font-semibold text-sm md:text-base text-white tracking-tight;
}

.subFooter {
  @apply container mx-auto px-4 md:px-6 mt-12 mb-12 justify-center;
}

.subFooterBody {
  @apply flex flex-col md:flex-row gap-12 md:gap-0 justify-between;
}

.subFooterNav {
  @apply flex flex-col md:flex-row gap-4 text-4xs md:text-3xs;
}

.footerCopyright {
  @apply font-semibold text-5xs md:text-3xs;
}

.certificateContainer {
  @apply flex flex-row md:justify-end mt-5;
}

.isoContainer {
  @apply h-12 w-12;
}
