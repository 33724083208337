.mobileNavigation {
  @apply hidden h-screen pb-20 overflow-y-scroll w-screen bg-background-color fixed top-0 left-0 z-50;
  transform: translateX(100vw);
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
}

.mobileNavigation.open {
  @apply block;
  transform: translateX(0);
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
}

.finalCtaButton {
  @apply bg-white text-background-color px-4 py-2.5 lg:px-5 lg:py-3 rounded-xs font-semibold text-xs lg:text-base ml-5 mt-9;
}
