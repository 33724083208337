.headerContainer {
  @apply px-5 md:px-6 sm:container mx-auto py-6 flex justify-center items-center;
}

.headerContainer.mini {
  @apply py-2 lg:py-2.5;
}

.logo {
  @apply -mt-0.5;
}

.mini .logo {
  @apply h-5;
}

.mini .logo svg {
  @apply h-full w-auto;
}

.leftNav {
  @apply flex flex-grow flex-row gap-12 items-center;
}

.leftNav nav {
  @apply hidden lg:block;
}

.leftNav nav ul {
  @apply flex flex-row gap-9 mt-0.5 font-medium text-2xs;
}

.mini .leftNav nav ul.leftNavList {
  @apply mt-0 text-4xs gap-9;
}

.leftNav ul li {
  @apply relative cursor-pointer;
}

.leftNav nav ul li a {
  @apply flex flex-row gap-2 items-center cursor-pointer;
}

.leftNav nav ul li svg {
  @apply mt-1;
}

.mini .leftNav nav ul.leftNavList li svg {
  @apply mt-0;
}

.mini .leftNav nav ul.leftNavList li.resourcesNav svg {
  @apply mt-1;
}

.headerButton {
  @apply hidden lg:block bg-lightest-gray-color text-background-color rounded-xs px-3.5 py-2.5 font-semibold;
}

.mini .headerButton {
  @apply text-3xs py-1.5 px-3;
}

.leftNav nav ul li:hover .subList {
  @apply visible opacity-100 block;
}

.mobileNav {
  @apply mt-9;
}

.mobileNav ul {
  @apply flex flex-col gap-9;
}

.mobileNav ul li a.dropdownListLink svg {
  width: 18px;
  height: 10px;
}

.mobileNav ul li.open a.dropdownListLink svg {
  transform: rotate(180deg);
}

.mobileNav ul li a {
  @apply flex flex-row justify-between items-center gap-4 px-5 font-normal text-xl;
}

.subList {
  @apply absolute top-full left-1/2 -translate-x-1/3 hidden opacity-0 z-10 bg-subnav-bg-color text-white rounded-sm pt-4 pb-7;
  transition: all 0.2s;
  visibility: hidden;
  height: 364px;
  width: 321px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(0, 0, 0, 0.06),
    0px 25px 50px -12px rgba(0, 0, 0, 0.25),
    inset 0px 1px 1px rgba(255, 255, 255, 0.1);
}

.mobileNav .subList {
  @apply hidden h-auto w-full shadow-none relative translate-x-0 bg-clear pb-0;
}

.mobileNav ul li.open .subList {
  @apply block visible top-auto left-auto opacity-100;
  z-index: 1000;
}

.mobileNav .subList h6 {
  @apply hidden;
}

.resourcesNav .subList {
  width: 321px;
  height: auto;
}

.productsNav .subList {
  height: 310px;
  width: 299px;
}

.mobileNav .resourcesNav .subList {
  height: auto;
  width: auto;
}

.mobileNav .productsNav .subList {
  width: auto;
  height: auto;
}

.subList h6 {
  @apply px-7 font-medium text-2xs leading-28;
}

.subList .subListItems {
  @apply mt-4 flex flex-col gap-0;
}

.subList .subListItems li {
  @apply py-3.5 px-9 transition-colors duration-150 ease-in;
}

.mobileNav .subList .subListItems li {
  @apply px-5;
}

.productsNav .subList .subListItems li {
  @apply pt-3.5 pb-5 w-full;
}

.mobileNav .productsNav .subList .subListItems li {
  @apply py-5;
}

.mobileNav .subList .subListItems li:hover {
  @apply bg-nav-item-shade;
}

.subList .subListItems li:hover {
  @apply bg-background-color;
}

.subList .subListItems li.monosign:hover {
  @apply bg-monosign-shade;
}

.subList .subListItems li.monopam:hover {
  @apply bg-monopam-shade;
}

.subList .subListItems li.monosync:hover {
  @apply bg-monosync-shade;
}

.subList .subListItemContent {
  @apply flex flex-row gap-3;
}

.subList .subListItemContent svg {
  @apply flex-shrink-0 pt-0.5 md:pt-0;
}

.subList .subListItemTitleAndDescription {
  @apply flex flex-col gap-1 font-medium text-sm leading-21 md:leading-21 tracking-tight pt-0 md:pt-0.75;
}

.subList .subListItemTitleAndDescription span {
  @apply font-normal text-4xs leading-5 tracking-normal;
}

.menuToggleButton {
  @apply block lg:hidden;
}
